<template>
  <v-card color="white" class="pa-8">
    <v-card-text class="px-0">
      <div>
        <div class="bsat__title">{{ $t("title") }}</div>
        <!-- ************************** OVERVIEW ***************************** -->

        <div class="bsat__paragraph">{{ $t("overview.paragraph1") }}</div>
        <div class="bsat__paragraph">{{ $t("overview.paragraph2") }}</div>

        <!-- ******************** SUMMARY *********************** -->

        <!-- <div class="bsat__subtitle mb-4">{{ $t("summary.title") }}</div>

        <ul class="bsat__paragraph">
          <li v-for="(item, i) in summaryUnorderedList" :key="i" class="my-1">
            <div>
              <span>{{ item.title }}</span>
              <ul class="bsat__paragraph" v-if="item.children">
                <li
                  v-for="(item, i) in getSummaryChildren(item.children)"
                  :key="i"
                  class="my-1"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </li>
        </ul>

        <div class="bsat__paragraph">{{ $t("summary.paragraph") }}</div> -->

        <!-- ************************ IMPLEMENTATION RECOMMENDATIONS ********************************* -->
        <section id="implementationRecommendations">
          <div class="bsat__subtitle mb-4">
            <strong>{{ $t("implementationRecommendations.title") }}</strong>
          </div>

          <ReadMore :intro="$t('implementationRecommendationReadMore')">
            <i18n
              path="implementationRecommendations.task.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.task.title")
                }}</strong>
              </template>
            </i18n>

            <i18n
              path="implementationRecommendations.taskLeader.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.taskLeader.title")
                }}</strong>
              </template>
            </i18n>

            <div class="bsat__paragraph mb-4">
              <strong>{{
                $t("implementationRecommendations.proposedMethods.title")
              }}</strong>
            </div>

            <div>
              <ul>
                <li
                  class="bsat__paragraph mb-4"
                  v-for="(item, i) in proposedMethods"
                  :key="i"
                >
                  <div>
                    <span>{{ item.title }}</span>
                    <ul v-if="item.children">
                      <li
                        class="bsat__paragraph mb-2"
                        v-for="(child, i) in getChildren(item)"
                        :key="i"
                      >
                        {{ child }}
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>

            <div class="bsat__paragraph">
              {{
                $t("implementationRecommendations.proposedMethods.paragraph")
              }}
            </div>
          </ReadMore>
        </section>
        <!-- ************************* RECOMMENDED RESOURCES ******************************* -->
        <section id="recommendedResources">
          <div class="bsat__subtitle mb-4">
            {{ $t("recomendedResources.title") }}
          </div>

          <!-- <div class="bsat__paragraph mt-n3 mb-6">
            {{ $t("recommendedResourcesIntro") }}
          </div> -->

          <ul class="ml-0 ml-sm-4 bsat__ul">
            <li>
              <div class="bsat__paragraph">
                {{ $t("recomendedResources.paragraph") }}
              </div>
            </li>

            <li>
              <div class="bsat__paragraph">
                {{ $t("recomendedResources.paragraph2") }}
              </div>
            </li>

            <li>
              <div>
                <i18n
                  path="recomendedResources.paragraph3.text"
                  tag="div"
                  class="bsat__paragraph"
                >
                  <template #link1>
                    <a @click="goToTab(2)">{{
                      $t("recomendedResources.paragraph3.link1")
                    }}</a>
                  </template>
                  <template #link2>
                    <a @click="goToTab(3)">{{
                      $t("recomendedResources.paragraph3.link2")
                    }}</a>
                  </template>
                </i18n>
              </div>
            </li>

            <li>
              <div>
                <i18n
                  path="recomendedResources.paragraph4.text"
                  tag="div"
                  class="bsat__paragraph"
                >
                  <template #link>
                    <a @click="goToStep(1)">{{
                      $t("recomendedResources.paragraph4.link")
                    }}</a>
                  </template>
                </i18n>
              </div>
            </li>

            <li>
              <div>
                <i18n
                  path="recomendedResources.paragraph5.text"
                  tag="div"
                  class="bsat__paragraph"
                >
                  <template #link>
                    <a @click="goToStep(2)">{{
                      $t("recomendedResources.paragraph5.link")
                    }}</a>
                  </template>
                </i18n>
              </div>
            </li>
          </ul>
        </section>
        <i18n
          class="mt-8"
          tag="div"
          path="downloadAll.text"
          style="font-family: Montserrat"
        >
          <template #link>
            <a class="dark-text mt-8" :href="zip" target="_blank" download>
              {{ $t("downloadAll.link") }}
            </a>
          </template>
        </i18n>
      </div>
    </v-card-text>
  </v-card>
</template>

<i18n>
{
  "en": {
    "implementationRecommendations": {
      "proposedMethods": {
        "paragraph": "Collecting consultation feedback and analysing the most relevant ones with decision-level of Interministerial/interdepartmental group and advisory group. ",
        "title": "Proposed methods",
        "unorderedList": [
          {
            "children": [
              "Sending final draft by email/interviews/validation meetings…",
              "Uploading final draft on regional ministries or governmental department´s web pages. ",
              "Other established channels for dissemination"
            ],
            "title": "Final consultation with stakeholders   "
          }
        ]
      },
      "task": {
        "text": "{title}: Final regional bioeconomy strategy and roadmap",
        "title": "Task"
      },
      "taskLeader": {
        "text": "{title}: Strategy development working group",
        "title": "Task leader"
      },
      "title": "Implementation Recommendations"
    },
    "overview": {
      "paragraph1": "After having a final draft internally, a consultation process is recommended to collect a final feedback and to enhance the recognition of the strategy. This action should involve key external advisors and key engaged stakeholders interested in the expected results and impacts of the new strategic document(s) The consultation might be requested directly, sending the final draft to specific institutions, and asking for their feedback. Also, the dissemination of the document by usual channels, such as regional administrations web pages, newsletters, between others would enlarge reaching out to interested entities and actors.",
      "paragraph2": "The final regional bioeconomy strategy and final roadmap might then be ratified by the regional administration."
    },
    "recomendedResources": {
      "paragraph": "Feedback from participatory approach.",
      "paragraph2": "Feedback from consultation process to the final draft of the strategy and roadmap",
      "paragraph3": {
        "link1": "Phase 2",
        "link2": "Phase 3",
        "text": "Regional bioeconomy vision ({link1}) Determined priority areas and their main goals ({link2})"
      },
      "paragraph4": {
        "link": "Step 1",
        "text": "Derived specific goals, corresponding actions and institutions responsible of their implementation ({link})"
      },
      "paragraph5": {
        "link": "Step 2",
        "text": "Assigned policy and financial mechanisms for the planned actions ({link})"
      },
      "title": "Recommended resources: "
    },
    "title": "Step 4: Final regional bioeconomy strategy and final roadmap",
    "downloadAll": {
			"link": "click here",
			"text": "*To download all the materials of Phase 3 and Phase 4 as a zip file please {link}"
		}
  }
}
</i18n>

<script>
import ReadMore from "@/components/ReadMore";
import { EventBus } from "@/utils/EventBus";
export default {
  name: "Phase4Step1",
  components: {
    ReadMore
  },
  data: () => ({
    zip: require("@/assets/files/Phase3_Phase4_common_resources.zip").default
  }),
  computed: {
    proposedMethods() {
      return Object.values(
        this.$t("implementationRecommendations.proposedMethods.unorderedList")
      );
    }
  },
  mounted() {
    EventBus.$on("goToStep", stepNumber => {
      this.steps = stepNumber;
    });
  },
  methods: {
    goToTab(tab) {
      EventBus.$emit("goToTab", tab);
    },
    goToStep(step) {
      EventBus.$emit("goToStep", step);
    },
    getChildren(item) {
      return Object.values(item.children);
    },
    getSummaryChildren(obj) {
      return Object.values(obj);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/main";

.list {
  $font-family: $font-family;
  // font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;

  &-link {
    color: $light_green !important;
  }
}
</style>
